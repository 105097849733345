import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { pointcarService, carService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'
import styled from 'styled-components'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { Box, Button, Modal } from '@mui/material'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'

const moment = require('moment')

export default function TabTelemetria(props) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '80%',
        height: '80vh',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    const [filter, setFilter] = useState({
        car: props.idcar,
        from:
            moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD') +
            'T00:00:00Z',
        to: null,
    })

    const [point, setPoint] = useState<any>(null)
    const [openModal, setOpenModal] = useState<any>(null)

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'from',
            inputtype: 'date',
            col: 3,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'date',
            col: 3,
        },
    ]

    const paintCell = {
        fuel_level_percent: (value, object) => {
            if (value) {
                return <>{value.toFixed(2)}%</>
            } else {
                return <></>
            }
        },
        createDate: (value, object) => {
            return (
                <>{moment(Date.parse(value)).format('DD/MM/YYYY HH:mm:ss')}</>
            )
        },
        move_status: (value, object) => {
            if (object.speed && object.speed > 0) {
                return <Labelok>En movimiento</Labelok>
            } else {
                return <Labelko>Parado</Labelko>
            }
        },
        engine_status: (value, object) => {
            if (value && value > 0) {
                return <Labelok>Arrancado</Labelok>
            } else {
                return <Labelko>Apagado</Labelko>
            }
        },
        door_status: (value, object) => {
            if (value && value == 1) {
                return <LockIcon style={{ color: 'green' }} />
            } else {
                return <LockOpenIcon style={{ color: 'red' }} />
            }
        },
        autonomia: (value, object) => {
            if (value > 0) {
                return <>{value.toFixed(2)}</>
            } else {
                return <></>
            }
        },
        speed: (value, object) => {
            return <>{value}</>
        },
    }

    useEffect(() => {}, [])

    const handleDownload = (d) => {
        setPoint(d)
    }

    useEffect(() => {
        if (point?.id) {
            setOpenModal(true)
        }
    }, [point])

    const handleCloseModal = () => {
        setOpenModal(false)
        setPoint(null)
    }

    return (
        <>
            <CrudTab
                service={pointcarService}
                filter={filter}
                i18n={'pointcar'}
                fieldstable={[
                    'createDate',
                    'lat',
                    'lng',
                    'km',
                    'move_status',
                    'engine_status',
                    'door_status',
                    'fuel_level',
                    'fuel_level_percent',
                    'autonomia',
                    'speed',
                ]}
                defaultValues={{ serie: '', number: 'Borrador' }}
                paintCell={paintCell}
                estructureForm={createEstructureFilter}
                haveother={false}
                selectable={false}
                deleteaction={false}
                editable={false}
                blockedinitsearch={false}
                withoutnumpage={true}
                moreActions={[
                    { handle: handleDownload, icon: <RemoveRedEyeIcon /> },
                ]}
            />
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={style}>
                    {point && point.lat && point.lng && (
                        <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                            <Map
                                center={{ lat: point.lat, lng: point.lng }}
                                zoom={18}
                                style={{
                                    width: '100%',
                                    height: 'calc( 100% - 100px )',
                                }}
                                styles={[
                                    {
                                        featureType: 'poi',
                                        stylers: [{ visibility: 'off' }],
                                    },
                                ]}
                            >
                                <Marker
                                    position={{
                                        lat: point.lat,
                                        lng: point.lng,
                                    }}
                                    title={point.number}
                                    icon={'/marker.png'}
                                />
                            </Map>
                        </APIProvider>
                    )}
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleCloseModal()}
                        >
                            {' '}
                            Cerrar
                        </Button>
                    </div>
                </Box>
            </Modal>
            <ToastContainer />
        </>
    )
}

const Labelok = styled.span`
    background: #83c63b;
    padding: 0.3em 1em;
    border-radius: 3px;
    color: white;
    display: inline-block;
    min-width: 130px;
    text-align: center;
`
const Labelko = styled.span`
    background: red;
    padding: 0.3em 1em;
    border-radius: 3px;
    color: white;
    display: inline-block;
    min-width: 130px;
    text-align: center;
`
