import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { adsService as service } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    name: null,
    picture: null,
    link: null,
    coupon: null,
    description: null,
    startDate: null,
    endDate: null,
    active: true,
    adsType: null,
    adsAction: null,
}

export default function FormAds() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'name',
            col: 6,
        },
        {
            type: 'image',
            name: 'picture',
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'active',
            col: 3,
        },
        {
            type: 'textarea',
            name: 'description',
            col: 6,
        },
        {
            type: 'input',
            name: 'startDate',
            inputtype: 'datetime-local',
            col: 3,
        },
        {
            type: 'input',
            name: 'endDate',
            inputtype: 'datetime-local',
            col: 3,
        },
        {
            type: 'title',
            name: 'linkconfig',
            col: 12,
        },
        {
            type: 'input',
            name: 'link',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'adsType',
            options: [
                { key: 'PRIN', name: 'Principales' },
                { key: 'SECOND', name: 'Secundario' },
            ],
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'adsAction',
            options: [
                { key: 'LINK', name: 'Link' },
                { key: 'MONEY', name: 'Monedero' },
                { key: 'SHARE', name: 'Share' },
                { key: 'RENT', name: 'Rental' },
            ],
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = { ...objectedit, ...element }
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('ads.error.name')
        }
        if (!object['picture']) {
            return t('ads.error.picture')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'ads'}
                urledit={'/ads/edit/'}
                urlCancel={'/ads'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
