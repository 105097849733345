import React, { useState, useEffect } from 'react'
import { pointcarService, carService } from '../../../services'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps'

const moment = require('moment')

const markers: google.maps.Marker[] = []
var flightPath: google.maps.Polyline | undefined | null = null
const ControlMap = (props) => {
    const map = useMap()

    const [points, setPoints] = React.useState<any[]>([])

    useEffect(() => {
        if (!map) return

        map.setOptions({
            center: { lat: 42.8802049, lng: -8.5447697 },
            zoom: 10,
            styles: [
                {
                    featureType: 'poi',
                    stylers: [{ visibility: 'off' }],
                },
            ],
        })
        searchCars()
    }, [map])

    const searchCars = () => {
        pointcarService.allFilter(props.filter, (data, error) => {
            if (data && data.data) {
                var aux: any[] = []
                data.data.forEach((element) => {
                    if (element.lat && element.lng) {
                        aux.push(element)
                    }
                })
                setPoints(aux)
            }
        })
    }

    const drawPoints = (points, map) => {
        if (points.length > 0 && map) {
            markers.forEach((element) => {
                element.setMap(null)
            })
            markers.length = 0

            var bounds = new google.maps.LatLngBounds()

            const marker = new window.google.maps.Marker({
                map,
                position: {
                    lat: points[0].lat,
                    lng: points[0].lng,
                },
                icon: '/marker.png',
            })

            markers.push(marker)
            const flightPlanCoordinates: any[] = []
            points.forEach((element) => {
                bounds.extend(new google.maps.LatLng(element.lat, element.lng))
                flightPlanCoordinates.push({
                    lat: element.lat,
                    lng: element.lng,
                })
            })
            map?.fitBounds(bounds)

            if (flightPath != null) {
                flightPath.setMap(null)
            }

            flightPath = new google.maps.Polyline({
                path: flightPlanCoordinates,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
            })

            flightPath.setMap(map)
        }
    }

    useEffect(() => {
        drawPoints(points, map)
    }, [points, map])

    useEffect(() => {
        const interval = setInterval(() => searchCars(), 30000)

        return () => clearInterval(interval)
    }, [])

    return <></>
}

export default function TabInLive(props) {
    const [filter, setFilter] = useState({
        car: props.idcar,
        from:
            moment(new Date()).subtract(10, 'minutes').format('YYYY-MM-DD') +
            'T00:00:00Z',
        to: null,
    })

    const { t } = useTranslation()

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            car: props.idcar,
            from:
                moment(new Date())
                    .subtract(10, 'minutes')
                    .format('YYYY-MM-DD') + 'T00:00:00Z',
            to: null,
        })
    }, [props.idcar])

    return (
        <div>
            <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                <Map
                    style={{
                        width: '100%',
                        height: 'calc(100vh - 400px)',
                    }}
                ></Map>
                <ControlMap filter={filter} />
            </APIProvider>
        </div>
    )
}
