export const isObject = (obj) => obj && typeof obj === 'object'

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
export const deepMerge = (target, source) => {
    if (!isObject(target) || !isObject(source)) {
        return source
    }

    Object.keys(source).forEach((key) => {
        const targetValue = target[key]
        const sourceValue = source[key]

        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
            target[key] = targetValue.concat(sourceValue)
        } else if (isObject(targetValue) && isObject(sourceValue)) {
            target[key] = deepMerge(Object.assign({}, targetValue), sourceValue)
        } else {
            target[key] = sourceValue
        }
    })

    return target
}

export const pad = (num: number, size: number): string => {
    let s = num + ''
    while (s.length < size) s = '0' + s
    return s
}
