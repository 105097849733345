import CrudTable from '../../crud/CrudTable'
import { bonoService as service } from '../../../services'

const initFilter = {
    active: -1,
    name: null,
}

export default function Bono() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'active',
            options: [
                { key: 1, name: 'Todos' },
                { key: 2, name: 'Activos' },
                { key: 3, name: 'Desactivados' },
            ],
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/bono/edit/'}
            urlnew={'/bono/new/'}
            filter={initFilter}
            i18n={'bono'}
            fieldstable={['name', 'money', 'gift', 'days', 'active']}
            estructureForm={createEstructureFilter}
        />
    )
}
