import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'

export default function DisableApp(props) {
    return (
        <div>
            <Container style={{ textAlign: 'center' }}>
                <img
                    src={'/logo.png'}
                    style={{
                        width: '400px',
                        maxWidth: '80%',
                        marginBottom: '2em',
                    }}
                />
                <p>
                    Estamos realizando cambios en la plataforma, por lo que
                    estará innacesible durante unas horas. Por favor disculpa la
                    molestias.
                </p>
            </Container>
        </div>
    )
}
