import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { couponService as service } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    name: '',
    code: '',
    km_discount: 0,
    price_discount: 0,
    time_discount: 0,
    time_standby_discount: 0,
    percentage_discount: 0,
    start_date: null,
    end_date: null,
    multiple_uses: 0,
    emails: '',
}

export default function FormCoupon() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'code',
            col: 4,
        },
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'start_date',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'end_date',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'start_date_rent',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'input',
            name: 'end_date_rent',
            inputtype: 'datetime-local',
            col: 4,
        },
        {
            type: 'title',
            name: 'titledis',
            col: 12,
        },
        {
            type: 'input',
            name: 'km_discount',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'price_discount',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'time_discount',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'time_standby_discount',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'percentage_discount',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'title',
            name: 'titleuses',
            col: 12,
        },
        {
            type: 'input',
            name: 'multiple_uses',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'limitUses',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'emails',
            col: 4,
        },
        {
            type: 'title',
            name: 'titlewhere',
            col: 12,
        },
        {
            type: 'checkbox',
            name: 'inShare',
            col: 4,
        },
        {
            type: 'checkbox',
            name: 'inRent',
            col: 4,
        },
        {
            type: 'checkbox',
            name: 'inMoney',
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('coupon.error.name')
        }
        if (!object['code']) {
            return t('coupon.error.name')
        }
        if (!object['start_date']) {
            return t('coupon.error.start_date')
        }
        if (!object['end_date']) {
            return t('coupon.error.end_date')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'coupon'}
                urledit={'/coupon/edit/'}
                urlCancel={'/coupon'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
