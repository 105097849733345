import {
    rentService as service,
    carService,
    userService,
    billService,
} from '../../../services'
import React, { useState, useEffect } from 'react'
import CrudEmpty from '../../crud/CrudEmpty'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'

const moment = require('moment')

type RentHistoryParams = {
    id: string
}

export default function RentHistory() {
    var { id } = useParams<RentHistoryParams>()

    const [{ rental, history }, setState] = useState({
        rental: {
            car: { id: -1, name: '', number: '', brand: '', model: '' },
            carTypes: { id: -1, name: '' },
            prices: { id: -1, name: '' },
            user: { id: -1, name: '', lastname: '', email: '' },
            coupon: { id: -1, name: '' },
            zone: { id: -1, name: '' },
            startReserve: '',
            start: '',
            end: '',
            start_real: '',
            end_real: '',
            lat_point_start: 0.0,
            lng_point_start: 0.0,
            lat_point_end: 0.0,
            lng_point_end: 0.0,
            price: 0.0,
            total: 0.0,
            km_inlude: 0.0,
            km_real: 0.0,
            discount: 0.0,
            totalPay: 0.0,
            km_start: '',
            fuel_level_start: '',
            km_end: '',
            fuel_level_end: '',
            status_start: '',
            status_end: '',
            sharedPay: '',
            stree: '',
            city: '',
            state: '',
            country: '',
            postalCode: '',
            rentalState: '',
            paymentState: '',
            pin: '',
            lines: [],
            tokenorder: '',
            paymentData: { id: -1, name: '' },
            seguro: false,
            minor25: false,
            minor3: false,
            tokenPayment: '',
            tokenPaymentFiance: '',
        },
        history: [],
    })

    useEffect(() => {
        service.one(id, (data, error) => {
            if (data && data.data) {
                setState((currentState) => ({
                    ...currentState,
                    ['rental']: data.data,
                }))
            }
        })

        service.history(id, (data, error) => {
            if (data && data.data) {
                setState((currentState) => ({
                    ...currentState,
                    ['history']: data.data,
                }))
            }
        })
    }, [])

    return (
        <CrudEmpty i18n={'rent.history'}>
            <Grid container>
                <Grid sm={6}>
                    <p>
                        <b>Desde: </b>
                        <span>
                            {rental?.start_real != null
                                ? moment(rental?.start_real).format(
                                      'DD-MM-YYYY hh:mm'
                                  )
                                : ''}
                        </span>
                    </p>
                </Grid>
                <Grid sm={6}>
                    <p>
                        <b>Hasta: </b>
                        <span>
                            {rental?.end_real != null
                                ? moment(rental?.end_real).format(
                                      'DD-MM-YYYY hh:mm'
                                  )
                                : ''}
                        </span>
                    </p>
                </Grid>
                <Grid sm={6}>
                    <p>
                        <b>Usuario: </b>
                        <span>
                            {rental?.user?.name} {rental?.user?.lastname}{' '}
                            {rental?.user?.email}
                        </span>
                    </p>
                </Grid>
                <Grid sm={6}>
                    <p>
                        <b>Vehiculo: </b>
                        <span>
                            {rental?.car?.number} {rental?.car?.brand}{' '}
                            {rental?.car?.model}{' '}
                        </span>
                    </p>
                </Grid>
            </Grid>
            <div>
                <Grid
                    container
                    style={{
                        marginTop: '5em',
                        background: 'white',
                        borderRadius: '6px',
                        padding: '15px',
                        fontWeight: 'bold',
                    }}
                >
                    <Grid xs={12} sm={2}>
                        Conductor
                    </Grid>
                    <Grid xs={12} sm={2}>
                        H. Inicio
                    </Grid>
                    <Grid xs={12} sm={2}>
                        H. Fin
                    </Grid>
                    <Grid xs={12} sm={2}>
                        Km Inicio
                    </Grid>
                    <Grid xs={12} sm={2}>
                        Km Fin
                    </Grid>
                    <Grid xs={12} sm={2}>
                        Km real
                    </Grid>
                </Grid>
                {history.map((data) => {
                    return (
                        <Grid
                            container
                            style={{
                                marginTop: '1em',
                                background: 'white',
                                borderRadius: '6px',
                                padding: '15px',
                            }}
                        >
                            <Grid xs={12} sm={2}>
                                {data['user']['name']}
                            </Grid>
                            <Grid xs={12} sm={2}>
                                {data['start'] != null
                                    ? moment(data['start']).format(
                                          'DD-MM-YYYY hh:mm'
                                      )
                                    : ''}
                            </Grid>
                            <Grid xs={12} sm={2}>
                                {data['end'] != null
                                    ? moment(data['end']).format(
                                          'DD-MM-YYYY hh:mm'
                                      )
                                    : ''}
                            </Grid>
                            <Grid xs={12} sm={2}>
                                {data['km_start'] != null
                                    ? data['km_start']
                                    : ''}
                            </Grid>
                            <Grid xs={12} sm={2}>
                                {data['km_end'] != null ? data['km_end'] : ''}
                            </Grid>
                            <Grid xs={12} sm={2}>
                                {data['km_real'] != null ? data['km_real'] : ''}
                            </Grid>
                        </Grid>
                    )
                })}
            </div>
        </CrudEmpty>
    )
}
