export const selectIconCar = (car) => {
    var result = '/markerFree.png'

    if (car['statusCar'] && car['statusCar'] == 'INSHARE') {
        if (car['speed'] && car['speed'] > 0) {
            result = '/markerShareActivo.png'
        } else {
            result = '/markerShareInactivo.png'
        }
    } else if (car['statusCar'] && car['statusCar'] == 'INRENT') {
        if (car['speed'] && car['speed'] > 0) {
            result = '/markerRentActivo.png'
        } else {
            result = '/markerRentInactivo.png'
        }
    } else if (car['statusCar'] && car['statusCar'] == 'BLOCK') {
        result = '/markerBlock.png'
    } else if (car['statusCar'] && car['statusCar'] == 'RESERVED') {
        result = '/markerRentInactivo.png'
    }

    return result
}
