import CrudTable from "../../../crud/CrudTable";
import {statebillService as service} from "../../../../services"

const initFilter =  {
    name : null,
};

export default function StateBill() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      }
  ];




  return (
    <CrudTable
          service={service}
          urledit={"/statebill/edit/"}
          urlnew={"/statebill/new/"}
          filter={initFilter}
          i18n={"statebill"}
          fieldstable={["name"]}
          estructureForm={createEstructureFilter} />
  );
}
