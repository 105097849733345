import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { tripService as service, markService } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    name: '',
    marks: [],
}

export default function FormTrip() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'name',
            col: 6,
        },
        {
            type: 'input',
            name: 'km',
            inputtype: 'number',
            col: 4,
        },
        {
            type: 'checkbox',
            name: 'enabled',
            col: 2,
        },
        {
            type: 'textarea',
            name: 'summary',
            col: 12,
        },
        {
            type: 'autocomplete',
            name: 'marks',
            multiple: true,
            service: markService.combo,
            col: 12,
        },
        {
            type: 'title',
            name: 'titlegal',
            col: 12,
        },
        {
            type: 'gallery',
            name: 'images',
            imagekey: 'path',
            altkey: 'alt',
            idkey: 'id',
            col: 12,
        },
        {
            type: 'editor',
            name: 'description',
            height: 600,
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('trip.error.name')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'trip'}
                urledit={'/trip/edit/'}
                urlCancel={'/trip'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
