import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { zoneService as service } from '../../../services'
import { useTranslation } from 'react-i18next'
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps'
import { Grid } from '@mui/material'

const initElement = {
    name: '',
    marks: [],
    type: { id: null, name: '' },
}

const auxPoints: any[] = []
var poligono: google.maps.Polygon | any = null

const ControlMap = ({
    field,
    valueform,
    objectform,
    setState,
    setValueField,
}) => {
    const map = useMap()

    const [zones, setZones] = React.useState<any[]>([])

    useEffect(() => {
        if (!map) return

        auxPoints.length = 0

        searchZones()

        const image = '/ic_point.png'

        map.addListener('click', (e) => {
            const marker = new window.google.maps.Marker({
                map,
                position: e.latLng,
                draggable: true,
                icon: image,
            })

            marker.addListener('drag', (e) => {
                drawArea()
            })

            marker.addListener('rightclick', (e) => {
                var index = auxPoints.indexOf(marker)
                if (index > -1) {
                    auxPoints.splice(index, 1)
                }
                marker.setMap(null)
                drawArea()
            })

            auxPoints.push(marker)
            drawArea()
        })

        map.setOptions({
            center: { lat: 42.8802049, lng: -8.5447697 },
            zoom: 10,
            styles: [
                {
                    featureType: 'poi',
                    stylers: [{ visibility: 'off' }],
                },
            ],
        })

        return () => {
            auxPoints.length = 0
            if (poligono != null) {
                poligono.setMap(null)
            }
        }
    }, [map])

    const searchZones = () => {
        service.all((data, error) => {
            if (data && data.data) {
                var aux: any[] = []
                data.data.forEach((element) => {
                    if (element.id != objectform['id']) {
                        aux.push(element)
                    }
                })
                debugger
                setZones(aux)
            }
        })
    }

    useEffect(() => {
        if (
            !map ||
            !objectform ||
            !objectform[field.name] ||
            auxPoints.length > 0
        )
            return

        var pointsString = objectform[field.name]

        if (pointsString) {
            var points = pointsString.split('#')
            if (points.length > 0) {
                var bounds = new google.maps.LatLngBounds()

                points.forEach((element) => {
                    var latlng = element.split(',')
                    if (latlng.length === 2) {
                        addPoint(+latlng[0], +latlng[1])
                        bounds.extend(
                            new google.maps.LatLng(+latlng[0], +latlng[1])
                        )
                    }
                })

                drawArea()

                map?.fitBounds(bounds)
            }
        }
    }, [map, objectform])

    useEffect(() => {
        debugger
        if (!map || !zones || zones.length <= 0) return

        drawAreas()
    }, [map, zones])

    const addPoint = (lat, lng) => {
        const image = '/ic_point.png'

        const marker = new window.google.maps.Marker({
            map,
            position: { lat: lat, lng: lng },
            draggable: true,
            icon: image,
        })

        marker.addListener('drag', (e) => {
            drawArea()
        })

        marker.addListener('rightclick', (e) => {
            var index = auxPoints.indexOf(marker)
            if (index > -1) {
                auxPoints.splice(index, 1)
            }
            marker.setMap(null)
            drawArea()
        })

        auxPoints.push(marker)
    }

    const drawAreas = () => {
        if (zones != null && zones.length > 0) {
            zones.forEach((element) => {
                var pointsString = element[field.name]

                if (pointsString) {
                    var points = element['points'].split('#')
                    if (points.length > 0) {
                        var bounds = new google.maps.LatLngBounds()

                        const flightPlanCoordinates: any[] = []
                        points.forEach((element) => {
                            var latlng = element.split(',')
                            if (latlng.length === 2) {
                                bounds.extend(
                                    new google.maps.LatLng(
                                        +latlng[0],
                                        +latlng[1]
                                    )
                                )
                                flightPlanCoordinates.push({
                                    lat: +latlng[0],
                                    lng: +latlng[1],
                                })
                            }
                        })

                        var poligono = new google.maps.Polygon({
                            paths: flightPlanCoordinates,
                            geodesic: true,
                            strokeColor: element.color,
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                            fillColor: element.color,
                            fillOpacity: 0.5,
                        })

                        poligono.setMap(map)
                    }
                }
            })
        }
    }

    const drawArea = () => {
        if (auxPoints != null && auxPoints.length > 1) {
            var pointtosave = ''
            const flightPlanCoordinates: any[] = []
            auxPoints.forEach((element) => {
                flightPlanCoordinates.push({
                    lat: element.position.lat(),
                    lng: element.position.lng(),
                })

                pointtosave =
                    pointtosave +
                    element.position.lat() +
                    ',' +
                    element.position.lng() +
                    '#'
            })

            if (poligono != null) {
                poligono.setMap(null)
            }

            poligono = new google.maps.Polygon({
                paths: flightPlanCoordinates,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
            })

            var newobject = { ...objectform }
            newobject[field.name] = pointtosave
            newobject['lat'] = auxPoints[0].position.lat()
            newobject['lng'] = auxPoints[1].position.lng()

            var update = { objectform: newobject }
            setState((currentState) => ({ ...currentState, ...update }))

            poligono.setMap(map)
        } else {
            if (poligono != null) {
                poligono.setMap(null)
            }
            var newobjectElse = { ...objectform }
            newobjectElse[field.name] = ''
            newobject['lat'] = null
            newobject['lng'] = null

            var updateElse = { objectform: newobjectElse }
            setState((currentState) => ({ ...currentState, ...updateElse }))
        }
    }

    return <></>
}

export default function FormZone() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const drawmap = function (
        field,
        valueform,
        objectform,
        setState,
        setValueField
    ) {
        return (
            <>
                <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                    <Map
                        style={{
                            width: '100%',
                            height: 'calc(100vh - 450px)',
                        }}
                    ></Map>
                    <ControlMap
                        field={field}
                        valueform={valueform}
                        objectform={objectform}
                        setState={setState}
                        setValueField={setValueField}
                    />
                </APIProvider>
            </>
        )
    }

    const estructureform = [
        {
            type: 'input',
            name: 'name',
            col: 3,
        },
        {
            type: 'input',
            name: 'price',
            inputtype: 'number',
            col: 3,
        },
        {
            type: 'input',
            name: 'pricesDistance',
            inputtype: 'number',
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'enable',
            col: 3,
        },
        {
            type: 'input',
            name: 'color',
            inputtype: 'color',
            col: 3,
        },
        {
            type: 'custom',
            name: 'points',
            custom: drawmap,
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        objectedit['price'] = element.price ? element.price : 0
        objectedit['enable'] = element.enable ? element.enable : false
        objectedit['points'] = element.points
        objectedit['lat'] = element.lat
        objectedit['lng'] = element.lng
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('zone.error.name')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'zone'}
                urledit={'/zone/edit/'}
                urlCancel={'/zone'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
