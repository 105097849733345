import CrudTable from '../../crud/CrudTable'
import { deviceService as service } from '../../../services'

const initFilter = {
      can_program_number: null,
      can_serial_number: null,
      device_model_name: null,
      flespi_channel: null,
      flespi_id: null,
      mac: null,
      phone: null,
      serial_number: null,
      sim_number: null,
      sim_operator: null,
      uuid: null,
}

export default function Device() {
      const createEstructureFilter = [
            {
                  type: 'input',
                  name: 'uuid',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'phone',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'device_model_name',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'serial_number',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'mac',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'can_serial_number',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'can_program_number',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'flespi_id',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'flespi_channel',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'sim_operator',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'sim_number',
                  col: 4,
            },
      ]

      return (
            <CrudTable
                  service={service}
                  urledit={'/device/edit/'}
                  urlnew={'/device/new/'}
                  filter={initFilter}
                  i18n={'device'}
                  fieldstable={[
                        'device_model_name',
                        'serial_number',
                        'can_program_number',
                        'can_serial_number',
                        'flespi_channel',
                        'mac',
                        'phone',
                        'sim_number',
                        'sim_operator',
                        'uuid',
                  ]}
                  estructureForm={createEstructureFilter}
            />
      )
}
