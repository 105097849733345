import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import { isNotLoginLogin, rolUser } from '../../utils/session';


const PrivateRoute = ({ children}) => {



  const isLoggedNotIn = isNotLoginLogin();
  const isAdmin = rolUser() == 'ADMIN';

  if(!isLoggedNotIn && isAdmin) {
    return  children;
  } else {
    return <Navigate to={{ pathname: '/login'}} />;
  }

}

export default PrivateRoute