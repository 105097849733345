import BaseService from './_base.service';

const URL = "/admin/bill";

export const billService = {
    search,
    combo,
    comboState,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    print,
    send,
    rectificative,
    deliverynote,
    deliverynotes,
    preform,
    generateFromDeliveryNotes,
    generateFromDeliveryNotesConfirm,
    toremesa,
    toremesaconfirm,
    allFilter,
    paycomplete,
    payparcial,
    reportremesa,
    contable,
    model347,
    model347Client,
    model347ClientPrint,
    deletepay,
    printDirect,
    printSeverals,
    printDirectSeverals,
    printSeveralsFilter,
    printDirectSeveralsFilter,
    allFilterPdf,
    allFilterExcel,
    allFilterWord,
    allFilterPrint,
    toremesaconfirmPost,
    allFilterIvaExcel,
    deleteIndividualPay,
    model347Pdf,
    model347Excel,
    model347Word

};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}
function comboState(id,callback) {
    return BaseService.get(URL+"/combo/state/"+id, callback, true)
}
function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}

function allFilter(filter,callback) {
    return BaseService.post(URL+"/all", filter, callback, true)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}

function print(id,name,callback) {
    return BaseService.getFile(URL + "/print/"+id,name, callback);
}

function printSeverals(ids,name,callback) {
    let body = JSON.stringify(ids)
    return BaseService.postFile(URL + "/print/several",body,name, callback);
}

function printDirect(id,name,callback) {
    return BaseService.getFilePrint(URL + "/print/"+id,name, callback);
}
function printDirectSeverals(ids,name,callback) {
    let body = JSON.stringify(ids)
    return BaseService.postFilePrint(URL + "/print/several",body,name, callback);
}
function printSeveralsFilter(filter,name,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL + "/print/several/filter",body,name, callback);
}
function printDirectSeveralsFilter(filter,name,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFilePrint(URL + "/print/several/filter",body,name, callback);
}
function send(id,name,callback) {
    return BaseService.get(URL + "/send/"+id,name, callback);
}
function rectificative(id,callback) {
    return BaseService.get(URL + "/rectificative/"+id, callback);
}
function deliverynote(id,callback) {
    return BaseService.get(URL + "/deliverynote/"+id, callback);
}

function preform(id,callback) {
    return BaseService.get(URL + "/preform/"+id, callback);
}

function deliverynotes(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL + "/deliverynotes/several", body, callback, true)
}

function generateFromDeliveryNotes (month,year,billtype, callback){
    debugger;
    return BaseService.get(URL + "/deliverynotes/month/"+month+"/"+year+"/"+billtype, callback);
}

function generateFromDeliveryNotesConfirm (month,year,billtype, callback){
    return BaseService.get(URL + "/deliverynotes/month/"+month+"/"+year+"/"+billtype+"/confirm", callback);
}

function toremesa (callback){
    return BaseService.get(URL + "/toremesa", callback);
}

function toremesaconfirm (nameFile, callback){
    return BaseService.getFile(URL + "/toremesa/confirm",nameFile, callback);
}


function toremesaconfirmPost (ids,nameFile, callback){
    return BaseService.postFile(URL + "/toremesa/confirm",ids,nameFile, callback);
}


function paycomplete (id, type,callback){
    return BaseService.get(URL + "/"+id+"/complete/"+type, callback);
}


function payparcial(id,payment, callback){
    let body = JSON.stringify(payment)
    return BaseService.post(URL + "/"+id+"/payparcial",body, callback);
}

function reportremesa (year, callback){
    return BaseService.get(URL + "/report/remesa/"+year, callback);
}

function contable(from,to,serie,name,callback) {
    return BaseService.getFile(URL + "/contable/"+from+"/"+to+"/"+serie,name, callback);
}


function model347 (year, callback){
    return BaseService.get(URL + "/model347/"+year, callback);
}

function model347Pdf (year, callback){
    return BaseService.getFile(URL + "/model347/"+year+"/export/pdf","modelo437-"+year+".pdf", callback);
}


function model347Excel (year, callback){
    return BaseService.getFile(URL + "/model347/"+year+"/export/excel","modelo437-"+year+".xlsx", callback);
}

function model347Word (year, callback){
    return BaseService.getFile(URL + "/model347/"+year+"/export/word","modelo437-"+year+".docx", callback);
}



function model347Client (year,id, callback){
    return BaseService.get(URL + "/model347/"+year+"/client/"+id, callback);
}

function model347ClientPrint (year,id,name, callback){
    return BaseService.getFile(URL + "/model347/"+year+"/client/"+id+"/print",name, callback);
}

function deletepay (id, callback){
    return BaseService.get(URL + "/"+id+"/deletepay", callback);
}


function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"facturas.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"facturas.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"facturas.docx", callback,)
}

function allFilterPrint(filter,callback) {
    return BaseService.postFile(URL+"/all/print", filter,"facturaspendientes.xls", callback)
}

function allFilterIvaExcel(filter,name,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel/iva", filter,name, callback,)
}
function deleteIndividualPay (id, callback){
    return BaseService.delete(URL + "/deleteIndividualPay/"+id, callback);
}
