import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../../crud/CrudForm'
import {
    modelcarService as service,
    cartypeService,
} from '../../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    name: '',
    brand: '',
    model: '',
    picture: '',
    pictureSecure: '',
    cardType: { id: null, name: null },
    fuel_capacity: 0.0,
    seats: 4,
    bateryOk: 12,
    bateryWarning: 11.6,
    bateryKO: 11.2,
    typeChange: '',
}

export default function FormModelcar() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'brand',
            col: 4,
        },
        {
            type: 'input',
            name: 'model',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'cardType.id',
            service: cartypeService.combo,
            col: 4,
        },
        {
            type: 'input',
            name: 'seats',
            inputtype: 'number',
            step: 1,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'typeChange',
            options: [
                { key: '', name: '' },
                { key: 'MANUAL', name: 'MANUAL' },
                { key: 'AUTOMATIC', name: 'AUTOMATICO' },
            ],
            col: 4,
        },
        {
            type: 'input',
            name: 'bateryOk',
            inputtype: 'number',
            step: 0.1,
            col: 4,
        },
        {
            type: 'input',
            name: 'bateryWarning',
            inputtype: 'number',
            step: 0.1,
            col: 4,
        },
        {
            type: 'input',
            name: 'bateryKO',
            inputtype: 'number',
            step: 0.1,
            col: 4,
        },
        {
            type: 'input',
            name: 'fuel_capacity',
            inputtype: 'number',
            step: 0.1,
            col: 4,
        },
        {
            type: 'image',
            name: 'picture',
            col: 2,
        },
        {
            type: 'image',
            name: 'pictureSecure',
            col: 2,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('modelcar.error.name')
        }
        if (!object['picture']) {
            return t('modelcar.error.picture')
        }
        if (!object['model']) {
            return t('modelcar.error.model')
        }
        if (!object['pictureSecure']) {
            return t('modelcar.error.pictureSecure')
        }
        if (!object['cardType'] && !object['cardType']['id']) {
            return t('modelcar.error.cardType.id')
        }
        if (!object['fuel_capacity']) {
            return t('modelcar.error.fuel_capacity')
        }
        if (!object['seats']) {
            return t('modelcar.error.seats')
        }
        if (!object['bateryOk']) {
            return t('modelcar.error.bateryOk')
        }
        if (!object['bateryWarning']) {
            return t('modelcar.error.bateryWarning')
        }
        if (!object['bateryKO']) {
            return t('modelcar.error.bateryKO')
        }
        if (!object['typeChange']) {
            return t('modelcar.error.typeChange')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'modelcar'}
                urledit={'/modelcar/edit/'}
                urlCancel={'/modelcar'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
