import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { bonoService as service } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    name: null,
    money: null,
    gift: null,
    days: null,
    active: null,
}

export default function FormBono() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'name',
            col: 6,
        },
        {
            type: 'input',
            name: 'money',
            col: 3,
        },
        {
            type: 'input',
            name: 'gift',
            col: 6,
        },
        {
            type: 'input',
            name: 'days',
            col: 6,
        },
        {
            type: 'checkbox',
            name: 'active',
            col: 3,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit = { ...objectedit, ...element }
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('ads.error.name')
        }
        if (!object['money']) {
            return t('ads.error.money')
        }
        if (!object['gift']) {
            return t('ads.error.gift')
        }
        if (!object['days']) {
            return t('ads.error.days')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'bono'}
                urledit={'/bono/edit/'}
                urlCancel={'/bono'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
