import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import {
    userService as service,
    moneymovementsService,
} from '../../../services'
import { useTranslation } from 'react-i18next'
import { Box, Button, Modal, TextField } from '@mui/material'
import Form from '../../form/Form'

const initElement = {
    email: '',
    pass: '',
    name: '',
    lastname: '',
    isClient: false,
    rol: { code: 'ADMIN', name: 'ADMIN' },
    enable: true,
    picture: null,
    acceptnews: false,
    acceptTerms: null,
    acceptPoli: null,
    nif: null,
    alias: null,
    street: null,
    city: null,
    state: null,
    postalcode: null,
    dni_front: null,
    dni_back: null,
    license_front: null,
    license_back: null,
    credit_number: null,
    credit_code: null,
    credit_month: null,
    credit_year: null,
    avatar: null,
    dni_day: null,
    dni_month: null,
    dni_year: null,
    license_day: null,
    license_month: null,
    license_year: null,
    bank_account: null,
    redsys_token: null,
    stripe_id: null,
    money: null,
    recarge: false,
    moneyRecarge: null,
    company: { id: null, name: null },
    validate: false,
}

type FormAdministratorParms = {
    id: string
}

export default function FormAdministrator() {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    const [objectform, setObjectform] = React.useState({ ...initElement })
    let { id } = useParams<FormAdministratorParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()
    const [openMoney, setOpenMoney] = useState(false)
    const [money, setMoney] = useState({
        user: { id: id },
        concept: null,
        money: null,
    })

    const estructureform = [
        {
            type: 'input',
            name: 'email',
            readonly: idRow > 0 ? true : false,
            col: 4,
        },
        {
            type: 'input',
            name: 'pass',
            col: 4,
            inputtype: 'password',
        },
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'lastname',
            col: 4,
        },
        {
            type: 'image',
            name: 'picture',
            col: 1,
        },
        {
            type: 'checkbox',
            name: 'acceptnews',
            readonly: true,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'validateState',
            options: [
                { key: 'NONE', name: 'Documentación no enviada' },
                { key: 'PENDING', name: 'Pendiente de validar' },
                { key: 'VALIDATE', name: 'Validada' },
                { key: 'REJECT', name: 'Rechazada' },
            ],
            readonly: true,
            col: 4,
        },
        {
            type: 'title',
            name: 'titledir',
            col: 12,
        },
        {
            type: 'input',
            name: 'street',
            col: 8,
        },
        {
            type: 'input',
            name: 'city',
            col: 4,
        },
        {
            type: 'input',
            name: 'postalcode',
            col: 4,
        },
        {
            type: 'input',
            name: 'state',
            col: 4,
        },
        {
            type: 'title',
            name: 'titledni',
            col: 12,
        },
        {
            type: 'image',
            name: 'dni_front',
            col: 2,
        },
        {
            type: 'image',
            name: 'dni_back',
            col: 2,
        },
        {
            type: 'input',
            name: 'dni_day',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'dni_month',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'dni_year',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'title',
            name: 'titlecond',
            col: 12,
        },
        {
            type: 'image',
            name: 'license_front',
            col: 2,
        },
        {
            type: 'image',
            name: 'license_back',
            col: 2,
        },
        {
            type: 'input',
            name: 'license_day',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'license_month',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'input',
            name: 'license_year',
            inputtype: 'number',
            step: 1,
            col: 2,
        },
        {
            type: 'title',
            name: 'titlepay',
            col: 12,
        },
        {
            type: 'input',
            name: 'bank_account',
            col: 8,
        },
        {
            type: 'input',
            name: 'redsys_token',
            readonly: true,
            col: 4,
        },
        {
            type: 'input',
            name: 'stripe_id',
            readonly: false,
            col: 4,
        },
        {
            type: 'title',
            name: 'titlemoney',
            col: 12,
        },
        {
            type: 'input',
            name: 'money',
            readonly: false,
            col: 4,
        },
        {
            type: 'checkbox',
            name: 'recarge',
            readonly: false,
            col: 4,
        },
        {
            type: 'input',
            name: 'moneyRecarge',
            readonly: false,
            col: 4,
        },
    ]

    const createEstructureMoney = [
        {
            type: 'input',
            name: 'concept',
            col: 6,
        },
        {
            type: 'input',
            name: 'money',
            inputtype: 'number',
            col: 6,
        },
    ]

    const handleCloseMoney = () => {
        setOpenMoney(false)
        setMoney({
            user: { id: id },
            concept: null,
            money: null,
        })
    }

    const saveMoney = (object) => {
        if (object['concept'] && object['concept'] !== '' && object['money']) {
            moneymovementsService.create(object, (data, error) => {
                if (data && data.data) {
                    handleCloseMoney()
                    window.location.reload()
                }
            })
        }
    }

    const openModalMoney = () => {
        setOpenMoney(true)
    }

    const recoveryElement = function (objectedit, element) {
        objectedit['email'] = element.email ? element.email : ''
        objectedit['name'] = element.name ? element.name : ''
        objectedit['lastname'] = element.lastname ? element.lastname : ''
        objectedit['rol'] = {
            code: element.rol && element.rol.code ? element.rol.code : null,
            name: element.rol && element.rol.name ? element.rol.name : null,
        }
        objectedit['laundry'] = {
            id:
                element.laundry && element.laundry.id
                    ? element.laundry.id
                    : null,
            name:
                element.laundry && element.laundry.name
                    ? element.laundry.name
                    : null,
        }
        objectedit['picture'] = element.picture ? element.picture : ''

        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('administrator.error.name')
        }
        if (!object['email']) {
            return t('administrator.error.email')
        }
        if (
            !object['rol'] ||
            !object['rol']['code'] ||
            !object['rol']['code'] == null
        ) {
            return t('administrator.error.rol')
        }

        return null
    }

    const moreActions = (getObject) => {
        var isGenerate = id && getObject && getObject()
        return (
            <>
                {isGenerate && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={openModalMoney}
                    >
                        {' '}
                        Añadir movimiento monedero
                    </Button>
                )}
            </>
        )
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'administrator'}
                urledit={'/administrator/edit/'}
                urlCancel={'/administrator'}
                service={service}
                recoveryElement={recoveryElement}
                moreActions={moreActions}
                valid={isValid}
            />
            <Modal
                open={openMoney}
                onClose={handleCloseMoney}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={style}>
                    <div style={{ marginBottom: '30px' }}>
                        ¿Quieres añadir un nuevo movimiento?
                    </div>
                    <div>
                        <Form
                            t={t}
                            i18n={'money'}
                            objectform={money}
                            fields={createEstructureMoney}
                            searchFilter={saveMoney}
                            cleanFilter={handleCloseMoney}
                        />
                    </div>
                </Box>
            </Modal>
        </>
    )
}
