import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { shareService, carService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

export default function TabShare(props) {
    const [filter, setFilter] = useState({
        car: null,
        user: props.idclient,
        from: null,
        to: null,
        rentalState: null,
        paymentState: null,
    })

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'car',
            service: carService.combo,
            col: 3,
        },
        {
            type: 'input',
            name: 'from',
            inputtype: 'date',
            col: 3,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'date',
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'paymentState',
            options: [
                { key: null, name: 'Todos' },
                { key: 'PAYMENT', name: 'Pagado' },
                { key: 'ERROR', name: 'Error en el pago' },
                { key: 'PARCIAL', name: 'Pagado parcialmente' },
            ],
            col: 3,
        },
    ]

    const paintCell = {
        total: (value, object) => {
            if (value) {
                return <>{value.toFixed(2)}</>
            } else {
                return <></>
            }
        },
        paymentState: (value, object) => {
            if (value) {
                if (value == 'PAYMENT') return <>PAGADO</>
                else if (value == 'ERROR') return <>ERROR EN EL PAGO</>
                else if (value == 'PARCIAL') return <>PAGADO PARCIALMENTE</>
                else return <></>
            } else {
                return <></>
            }
        },
    }

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            car: null,
            user: props.idclient,
            from: null,
            to: null,
            rentalState: null,
            paymentState: null,
        })
    }, [props.idclient])

    return (
        <>
            <CrudTab
                service={shareService}
                urledit={'/share/edit/'}
                urlnew={'/share/new/'}
                filter={filter}
                i18n={'share'}
                fieldstable={[
                    'car.name',
                    'start',
                    'end',
                    'total',
                    'paymentState',
                ]}
                defaultValues={{ serie: '', number: 'Borrador' }}
                paintCell={paintCell}
                estructureForm={createEstructureFilter}
                haveother={false}
                selectable={false}
            />
            <ToastContainer />
        </>
    )
}
