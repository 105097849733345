import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { moneymovementsService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

export default function TabMoney(props) {
    const [filter, setFilter] = useState({
        user: props.idclient,
        from: null,
        to: null,
    })

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'from',
            inputtype: 'date',
            col: 3,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'date',
            col: 3,
        },
    ]

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            user: props.idclient,
            from: null,
            to: null,
        })
    }, [props.idclient])

    return (
        <>
            <CrudTab
                service={moneymovementsService}
                urledit={'/rental/edit/'}
                urlnew={'/rental/new/'}
                deleteaction={false}
                editable={false}
                filter={filter}
                i18n={'money'}
                fieldstable={['concept', 'money']}
                estructureForm={createEstructureFilter}
                haveother={false}
                selectable={false}
            />
            <ToastContainer />
        </>
    )
}
