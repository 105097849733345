import Grid from '@mui/material/Grid'
import styled from 'styled-components'

export const setValueClientData = async (
    setValueField,
    objectform,
    value,
    valueform,
    setState,
    id,
    aux,
    client
) => {
    var newobject = { ...objectform }

    newobject = setValueField(newobject, 'client', client)

    newobject = setValueField(
        newobject,
        'nameClient',
        client.name + ' ' + client.lastname
    )
    newobject = setValueField(newobject, 'emailClient', client.email)
    newobject = setValueField(newobject, 'nif', client.nif)

    let valueFormAux = { ...valueform }
    valueFormAux['client.id'] = {
        key: client.id,
        name: client.name + ' ' + client.lastname,
    }

    valueFormAux['nameClient'] = client.name + ' ' + client.lastname
    valueFormAux['nif'] = client.nif ? client.nif : ''
    valueFormAux['emailClient'] = client.email ? client.email : ''

    var update = {
        objectform: newobject,
        valueform: valueFormAux,
    }

    setState((currentState) => ({ ...currentState, ...update }))

    return newobject
}

export const setValueAmout = (
    setValueField,
    objectform,
    value,
    valueform,
    setState,
    id,
    aux
) => {
    var newobject = { ...objectform }
    newobject = setValueField(newobject, 'ammount', value)

    var valueFormAux = { ...valueform }
    var rv = valueform[id]
    rv['ammount'] = value

    if (value) {
        var ammount = +value
        var priceWithIva = rv['priceWithIva']
            ? isNaN(+rv['priceWithIva'])
                ? 0
                : +rv['priceWithIva']
            : 0
        var percentIva = rv['percentIva']
            ? isNaN(+rv['percentIva'])
                ? 0
                : +rv['percentIva']
            : 0
        var percentRecargo = rv['percentRecargo']
            ? isNaN(+rv['percentRecargo'])
                ? 0
                : +rv['percentRecargo']
            : 0
        var tax = rv['tax'] ? (isNaN(+rv['tax']) ? 0 : +rv['tax']) : 0

        var total = priceWithIva * ammount
        var base = total / (1 + percentIva / 100)
        var iva = total - base
        var recargo = 0
        var price = priceWithIva / (1 + percentIva / 100)

        newobject = setValueField(newobject, 'base', base.toFixed(2))
        newobject = setValueField(newobject, 'iva', iva.toFixed(2))
        newobject = setValueField(newobject, 'recargo', recargo.toFixed(2))
        newobject = setValueField(newobject, 'total', total.toFixed(2))
        newobject = setValueField(newobject, 'price', price.toFixed(2))

        rv['base'] = base.toFixed(2)
        rv['iva'] = iva.toFixed(2)
        rv['total'] = total.toFixed(2)
    } else {
        newobject = setValueField(newobject, 'base', 0)
        newobject = setValueField(newobject, 'iva', 0)
        newobject = setValueField(newobject, 'price', 0)
        newobject = setValueField(newobject, 'total')

        rv['base'] = '0.00'
        rv['iva'] = '0.00'
        rv['total'] = '0.00'
    }

    var update = {
        valueform: valueFormAux,
        objectform: newobject,
    }
    setState((currentstate) => ({ ...currentstate, ...update }))

    return newobject
}

export const setValuePrice = (
    setValueField,
    objectform,
    value,
    valueform,
    setState,
    id,
    aux
) => {
    var newobject = { ...objectform }
    newobject = setValueField(newobject, 'priceWithIva', value)

    var valueFormAux = { ...valueform }
    var rv = valueform[id]
    rv['priceWithIva'] = value

    if (value) {
        var ammount = rv['amount']
            ? isNaN(+rv['amount'])
                ? 0
                : +rv['amount']
            : 0
        var priceWithIva = +value
        var percentIva = rv['percentIva']
            ? isNaN(+rv['percentIva'])
                ? 0
                : +rv['percentIva']
            : 0
        var percentRecargo = rv['percentRecargo']
            ? isNaN(+rv['percentRecargo'])
                ? 0
                : +rv['percentRecargo']
            : 0
        var tax = rv['tax'] ? (isNaN(+rv['tax']) ? 0 : +rv['tax']) : 0

        var total = priceWithIva * ammount
        var base = total / (1 + percentIva / 100)
        var iva = total - base
        var recargo = 0
        var price = priceWithIva / (1 + percentIva / 100)

        var newobject = objectform
        newobject = setValueField(newobject, 'base', base.toFixed(2))
        newobject = setValueField(newobject, 'iva', iva.toFixed(2))
        newobject = setValueField(newobject, 'total', total.toFixed(2))
        newobject = setValueField(newobject, 'price', price.toFixed(2))

        rv['base'] = base.toFixed(2)
        rv['iva'] = iva.toFixed(2)
        rv['total'] = total.toFixed(2)
    } else {
        newobject = setValueField(newobject, 'base', 0)
        newobject = setValueField(newobject, 'iva', 0)
        newobject = setValueField(newobject, 'total')
        newobject = setValueField(newobject, 'price', 0)

        rv['base'] = '0.00'
        rv['iva'] = '0.00'
        rv['total'] = '0.00'
    }

    var update = {
        valueform: valueFormAux,
        objectform: newobject,
    }
    setState((currentstate) => ({ ...currentstate, ...update }))

    return newobject
}

export const setValueIva = (
    setValueField,
    objectform,
    value,
    valueform,
    setState,
    id,
    aux
) => {
    var newobject = { ...objectform }
    newobject = setValueField(newobject, 'percentIva', value)

    var valueFormAux = { ...valueform }
    var rv = valueform[id]
    rv['percentIva'] = value

    if (value) {
        var ammount = rv['amount']
            ? isNaN(+rv['amount'])
                ? 0
                : +rv['amount']
            : 0
        var priceWithIva = rv['priceWithIva']
            ? isNaN(+rv['priceWithIva'])
                ? 0
                : +rv['priceWithIva']
            : 0
        var percentIva = +value

        var total = priceWithIva * ammount
        var base = total / (1 + percentIva / 100)
        var iva = total - base
        var recargo = 0
        var price = priceWithIva / (1 + percentIva / 100)

        newobject = setValueField(newobject, 'base', base.toFixed(2))
        newobject = setValueField(newobject, 'iva', iva.toFixed(2))
        newobject = setValueField(newobject, 'total', total.toFixed(2))
        newobject = setValueField(newobject, 'price', price.toFixed(2))

        rv['base'] = base.toFixed(2)
        rv['iva'] = iva.toFixed(2)
        rv['total'] = total.toFixed(2)
    } else {
        newobject = setValueField(newobject, 'base', 0)
        newobject = setValueField(newobject, 'iva', 0)
        newobject = setValueField(newobject, 'total')
        newobject = setValueField(newobject, 'price', 0)

        rv['base'] = '0.00'
        rv['iva'] = '0.00'
        rv['total'] = '0.00'
    }

    var update = {
        valueform: valueFormAux,
        objectform: newobject,
    }
    setState((currentstate) => ({ ...currentstate, ...update }))

    return newobject
}

export const updateRows = (
    setValueField,
    objectform,
    rows,
    setSate,
    valueform
) => {
    var totalLines = 0.0

    var base = 0
    var iva = 0
    var recargo = 0
    var total = 0
    var tax = 0

    var ivasLine: any[] = []
    var ivas = new Map<number, any>()

    for (let row of rows) {
        if (row['total']) {
            base += +row['base']
            iva += +row['iva']
            total += +row['total']
            tax += +row['amount'] * (+row['priceWithIva'] * (+row['tax'] / 100))

            if (ivas.has(+row['percentIva'])) {
                ivas.set(+[row['percentIva']], {
                    percentIva: row['percentIva'],
                    iva: +row['iva'] + ivas.get(+row['percentIva']).iva,
                })
            } else {
                ivas.set(+[row['percentIva']], {
                    percentIva: row['percentIva'],
                    iva: +row['iva'],
                })
            }
        }
    }

    ivas.forEach((value, key) => {
        ivasLine.push(value)
    })

    var disper = +objectform['discountPercent']
    var dis = (base * (disper / 100.0)).toFixed(2)

    if (disper && disper > 0) {
        ivasLine = []
        ivas = new Map<number, any>()

        base = 0
        iva = 0
        total = 0

        for (let row of rows) {
            if (row['total']) {
                var priceLine =
                    +row['priceWithIva'] - (+row['priceWithIva'] * disper) / 100
                var baseLine = priceLine * +row['amount']
                var ivaLine = baseLine * (+row['percentIva'] / 100)
                var totalLine = baseLine + ivaLine

                base += baseLine
                iva += ivaLine
                total += totalLine

                if (ivas.has(+row['percentIva'])) {
                    ivas.set(+[row['percentIva']], {
                        percentIva: row['percentIva'],
                        iva: ivaLine + ivas.get(+row['percentIva']).iva,
                    })
                } else {
                    ivas.set(+[row['percentIva']], {
                        percentIva: row['percentIva'],
                        iva: ivaLine,
                    })
                }
            }
        }

        ivas.forEach((value, key) => {
            ivasLine.push(value)
        })
    }

    let valueFormAux = { ...valueform }
    valueFormAux['total'] = total.toFixed(2)
    valueFormAux['discount'] = dis
    valueFormAux['base'] = base.toFixed(2)
    valueFormAux['iva'] = iva.toFixed(2)

    var newobject = { ...objectform }
    newobject = setValueField(newobject, 'total', total)
    newobject = setValueField(newobject, 'discount', dis)
    newobject = setValueField(newobject, 'base', base)
    newobject = setValueField(newobject, 'iva', iva)
    newobject = setValueField(newobject, 'ivasline', ivasLine)

    var update = {
        objectform: newobject,
        valueform: valueFormAux,
    }

    setSate((currentState) => ({ ...currentState, ...update }))

    return newobject
}

export const updateDiscountTotal = (
    setValueField,
    objectform,
    value,
    valueform,
    setState
) => {
    var rows = objectform['lines']

    var totalLines = 0.0

    var base = 0
    var iva = 0
    var recargo = 0
    var total = 0
    var tax = 0

    var ivasLine: any[] = []
    var recargosLine: any[] = []
    var ivas = new Map<number, any>()
    var recargos = new Map<number, any>()

    for (let row of rows) {
        if (row['total']) {
            base += +row['base']
            iva += +row['iva']
            total += +row['total']

            if (ivas.has(+row['percentIva'])) {
                ivas.set(+[row['percentIva']], {
                    percentIva: row['percentIva'],
                    iva: +row['iva'] + ivas.get(+row['percentIva']).iva,
                })
            } else {
                ivas.set(+[row['percentIva']], {
                    percentIva: row['percentIva'],
                    iva: +row['iva'],
                })
            }
        }
    }

    var disper = +value
    var dis = (base * (disper / 100.0)).toFixed(2)
    if (disper && disper > 0) {
        ivasLine = []
        ivas = new Map<number, any>()

        base = 0
        iva = 0
        total = 0

        for (let row of rows) {
            if (row['total']) {
                var priceLine =
                    +row['priceWithIva'] - (+row['priceWithIva'] * disper) / 100
                var baseLine = priceLine * +row['amount']
                var ivaLine = baseLine * (+row['percentIva'] / 100)
                var totalLine = baseLine + ivaLine

                base += baseLine
                iva += ivaLine
                total += totalLine

                if (ivas.has(+row['percentIva'])) {
                    ivas.set(+[row['percentIva']], {
                        percentIva: row['percentIva'],
                        iva: ivaLine + ivas.get(+row['percentIva']).iva,
                    })
                } else {
                    ivas.set(+[row['percentIva']], {
                        percentIva: row['percentIva'],
                        iva: ivaLine,
                    })
                }
            }
        }
    }

    ivas.forEach((value, key) => {
        ivasLine.push(value)
    })

    recargos.forEach((value, key) => {
        recargosLine.push(value)
    })

    let valueFormAux = { ...valueform }
    valueFormAux['discountPercent'] = value
    valueFormAux['total'] = total.toFixed(2)
    valueFormAux['discount'] = dis
    valueFormAux['base'] = base.toFixed(2)
    valueFormAux['iva'] = iva.toFixed(2)

    var newobject = { ...objectform }
    newobject = setValueField(newobject, 'discountPercent', value)
    newobject = setValueField(newobject, 'total', total.toFixed(2))
    newobject = setValueField(newobject, 'discount', dis)
    newobject = setValueField(newobject, 'base', base.toFixed(2))
    newobject = setValueField(newobject, 'iva', iva.toFixed(2))
    newobject['ivasline'] = ivasLine
    newobject = setValueField(newobject, 'recargosline', recargosLine)

    var update = {
        objectform: newobject,
        valueform: valueFormAux,
    }

    setState((currentState) => ({ ...currentState, ...update }))

    return newobject
}

export const fieldIvas = (
    field,
    valueform,
    objectform,
    setState,
    setValueField
) => {
    return (
        <Grid container spacing={3}>
            <Grid item sm={6}>
                <h5>Desglose de ivas</h5>
                <PanelIvas>
                    {objectform &&
                        objectform['ivasline'] &&
                        objectform['ivasline'].length > 0 &&
                        objectform['ivasline'][0].percentIva != 0 &&
                        objectform['ivasline'].map((row) => (
                            <PanelOneIva>
                                <PanelOneIvaLabel>
                                    {row.percentIva}%
                                </PanelOneIvaLabel>
                                <PanelOneIvaSpan>
                                    {row.iva.toFixed(2)}
                                </PanelOneIvaSpan>
                            </PanelOneIva>
                        ))}
                </PanelIvas>
            </Grid>
            <Grid item sm={6}>
                <h5>Desglose de recargo equivalencia</h5>
                <PanelIvas>
                    {objectform &&
                        objectform['recargosline'] &&
                        objectform['recargosline'].length > 0 &&
                        objectform['recargosline'][0].percent != 0 &&
                        objectform['recargosline'].map((row) => (
                            <PanelOneIva>
                                <PanelOneIvaLabel>
                                    {row.percent}%
                                </PanelOneIvaLabel>
                                <PanelOneIvaSpan>
                                    {row.amount.toFixed(2)}
                                </PanelOneIvaSpan>
                            </PanelOneIva>
                        ))}
                </PanelIvas>
            </Grid>
        </Grid>
    )
}

const PanelIvas = styled.div`
    display: flex;
`

const PanelOneIva = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 146px;
    width: 30%;
`

const PanelOneIvaLabel = styled.label`
    display: block;
    background-color: rgba(100, 172, 71, 0.7);
    padding: 5px 0;
`
const PanelOneIvaSpan = styled.span`
    display: block;
    background-color: rgba(100, 172, 71, 0.5);
    padding: 5px 0;
`
