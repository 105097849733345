import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { docService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'
import { Box, Button, Modal } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import Form from '../../form/Form'

export default function TabDocument(props) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    const [filter, setFilter] = useState({
        user: props.idclient,
        from: null,
        to: null,
    })

    const [doc, setDoc] = useState({
        user: { id: props.idclient },
        name: null,
        description: null,
        path: null,
    })

    const [openDoc, setOpenDoc] = useState(false)

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 3,
        },
    ]

    const createEstructureDoc = [
        {
            type: 'input',
            name: 'name',
            col: 3,
        },
        {
            type: 'input',
            name: 'description',
            col: 5,
        },
        {
            type: 'file',
            name: 'path',
            col: 4,
        },
    ]

    const handleCloseDoc = () => {
        setOpenDoc(false)
        setDoc({
            user: { id: props.idclient },
            name: null,
            description: null,
            path: null,
        })
    }

    const saveDoc = (object) => {
        if (
            object['path'] &&
            object['path'] !== '' &&
            object['name'] &&
            object['name'] !== ''
        ) {
            docService.create(object, (data, error) => {
                if (data && data.data) {
                    setFilter({
                        user: props.idclient,
                        from: null,
                        to: null,
                    })
                    handleCloseDoc()
                } else {
                    toast.error(
                        'Ha ocurrido un error, vuelva a intentarlo mas tarde',
                        {
                            position: toast.POSITION.TOP_CENTER,
                        }
                    )
                }
            })
        } else {
            toast.error('Debe indicar el documento y el nombre', {
                position: toast.POSITION.TOP_CENTER,
            })
        }
    }

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            user: props.idclient,
            from: null,
            to: null,
        })

        setDoc({
            user: { id: props.idclient },
            name: null,
            description: null,
            path: null,
        })
    }, [props.idclient])

    const handleDownload = (d) => {
        var url =
            process.env.REACT_APP_HOST +
            '/file' +
            (d.path.startsWith('/') ? '' : '/') +
            d.path
        var win = window.open(url, '_blank')
        if (win) {
            win.focus()
        }
    }

    return (
        <>
            <div style={{ textAlign: 'right' }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '30px' }}
                    onClick={() => setOpenDoc(true)}
                >
                    {t('doc.titlebutton')}
                </Button>
            </div>
            <CrudTab
                service={docService}
                editable={false}
                filter={filter}
                i18n={'doc'}
                fieldstable={['name', 'description']}
                estructureForm={createEstructureFilter}
                haveother={false}
                moreActions={[
                    { handle: handleDownload, icon: <DownloadIcon /> },
                ]}
                selectable={false}
            />
            <Modal
                open={openDoc}
                onClose={handleCloseDoc}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={style}>
                    <div style={{ marginBottom: '30px' }}>
                        ¿Quieres añadir un nuevo documento a este usuario?
                    </div>
                    <div>
                        <Form
                            t={t}
                            i18n={'doc'}
                            objectform={doc}
                            fields={createEstructureDoc}
                            searchFilter={saveDoc}
                            cleanFilter={handleCloseDoc}
                        />
                    </div>
                </Box>
            </Modal>
            <ToastContainer />
        </>
    )
}
