import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { deviceService as service } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    bt_pin: null,
    can_program_number: null,
    can_serial_number: null,
    device_model_name: null,
    flespi_channel: null,
    flespi_id: null,
    internal_id: null,
    mac: null,
    phone: null,
    serial_number: null,
    sim_number: null,
    sim_operator: null,
    sim_pin: null,
    sim_puk: null,
    sms_pin: null,
    uuid: null,
}

export default function FormDevice() {
    const { t } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'uuid',
            col: 4,
        },
        {
            type: 'input',
            name: 'phone',
            col: 4,
        },
        {
            type: 'input',
            name: 'device_model_name',
            col: 4,
        },
        {
            type: 'input',
            name: 'serial_number',
            col: 4,
        },
        {
            type: 'input',
            name: 'mac',
            col: 4,
        },
        {
            type: 'input',
            name: 'internal_id',
            col: 4,
        },
        {
            type: 'input',
            name: 'can_serial_number',
            col: 4,
        },
        {
            type: 'input',
            name: 'can_program_number',
            col: 4,
        },
        {
            type: 'input',
            name: 'flespi_id',
            col: 4,
        },
        {
            type: 'input',
            name: 'flespi_channel',
            col: 4,
        },
        {
            type: 'input',
            name: 'sim_operator',
            col: 4,
        },
        {
            type: 'input',
            name: 'sim_number',
            col: 4,
        },
        {
            type: 'input',
            name: 'sim_pin',
            col: 4,
        },
        {
            type: 'input',
            name: 'sim_puk',
            col: 4,
        },
        {
            type: 'input',
            name: 'sms_pin',
            col: 4,
        },
        {
            type: 'input',
            name: 'bt_pin',
            col: 4,
        },
    ]

    const recoveryElement = function (
        objectedit: { [x: string]: any },
        element: { name: any }
    ) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['bt_pin'] || object['bt_pin'] === '') {
            return t('device.error.bt_pin')
        }
        /*if (!object['can_serial_number'] || object['can_serial_number'] === '') {
   return t('device.error.can_serial_number')
  }*/
        if (
            !object['device_model_name'] ||
            object['device_model_name'] === ''
        ) {
            return t('device.error.device_model_name')
        }
        if (!object['flespi_channel'] || object['flespi_channel'] === '') {
            return t('device.error.flespi_channel')
        }
        /* if (!object['flespi_id'] || object['flespi_id'] === '') {
   return t('device.error.flespi_id')
  }
  if (!object['mac'] || object['mac'] === '') {
   return t('device.error.mac')
  }*/
        if (!object['phone'] || object['phone'] === '') {
            return t('device.error.phone')
        }
        if (!object['sim_number'] || object['sim_number'] === '') {
            return t('device.error.sim_number')
        }
        if (!object['sim_operator'] || object['sim_operator'] === '') {
            return t('device.error.sim_operator')
        }
        if (!object['sim_pin'] || object['sim_pin'] === '') {
            return t('device.error.sim_pin')
        }
        if (!object['sim_puk'] || object['sim_puk'] === '') {
            return t('device.error.sim_puk')
        }
        if (!object['sms_pin'] || object['sms_pin'] === '') {
            return t('device.error.sms_pin')
        }
        if (!object['uuid'] || object['uuid'] === '') {
            return t('device.error.uuid')
        }
        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'device'}
                urledit={'/device/edit/'}
                urlCancel={'/device'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
