import React, { useState, useEffect } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'toasted-notes/src/styles.css'
import CrudForm from '../../crud/CrudForm'
import Button from '@mui/material/Button'
import {
    billService as service,
    userService,
    statebillService,
} from '../../../services'
import {
    initElementStreet,
    estructureformStreet,
    getElementStreet,
    headerFecth,
} from '../../../utils/Data'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import styled from 'styled-components'
import { pad } from '../../../utils/object'
import {
    setValueAmout,
    setValuePrice,
    setValueIva,
    updateRows,
    updateDiscountTotal,
    setValueClientData,
    fieldIvas,
} from '../../../utils/UtilsBill'

const moment = require('moment')

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

type FormBillmParams = {
    id: string
}

export default function FormBill() {
    const [reload, setReload] = useState(false)
    const [hiddenTax, setHiddenTax] = useState(true)
    const [client, setClient] = useState(false)
    const navigate = useNavigate()
    let { id } = useParams<FormBillmParams>()
    const { t, i18n } = useTranslation()
    const [forcesave, setForcesave] = useState(false)
    const [openDeletePay, setOpenDeletePay] = React.useState(false)

    let clientGeneral: any = { id: null, comercialName: null }
    let prices = new Map<number, any>()

    const initline = {
        id: -1,
        amount: 0.0,
        price: 0.0,
        percentIva: 21.0,
        iva: 0.0,
        total: 0.0,
        base: 0.0,
        discount: 0.0,
        discountPercent: 0.0,
    }

    const initElement = {
        client: { id: null, name: '' },
        number: null,
        date: null,
        state: { id: 1, name: 'Borrador' },
        nameClient: '',
        emailClient: '',
        serie: '',
        nif: '',
        total: 0.0,
        base: 0.0,
        iva: 0.0,
        discountPercent: 0.0,
        discount: 0.0,
        note: null,
        paymentType: { id: null, name: '' },
        route: { id: null, name: '' },
        recargoEqui: false,
        exentoIva: false,
        lines: [],
        ivasline: [],
        recargosline: [],
        street: { ...initElementStreet },
        isPay: null,
        payone: null,
        payoneDate: null,
        paytwo: null,
        paytwoDate: null,
        paythree: null,
        paythreeDate: null,
        payTotal: null,
        payLess: null,
        hiddenTax: true,
    }

    const [objectform, setObjectform] = React.useState({ ...initElement })

    const setValueClient = async (
        setValueField,
        objectform,
        value,
        valueform,
        setSate,
        id,
        aux
    ) => {
        if (value && (value.key || value.key === 0)) {
            var newobject = objectform

            let url =
                process.env.REACT_APP_HOST + '/admin/user/one/' + value.key
            const responseblog = await fetch(url, {
                method: 'GET',
                headers: headerFecth(),
            })
            const jsoncomplete = await responseblog.json()

            if (jsoncomplete && jsoncomplete.data && jsoncomplete.data) {
                var client = jsoncomplete.data
                clientGeneral = client
                setValueClientData(
                    setValueField,
                    objectform,
                    value,
                    valueform,
                    setSate,
                    id,
                    aux,
                    client
                )
            }

            return newobject
        } else {
            return objectform
        }
    }

    const checkIsReadOnly = function (object) {
        return object && object.number && object.number > 0
    }

    const estructureformline = [
        {
            type: 'input',
            name: 'name',
            colSpan: '30%',
            col: 4,
        },
        {
            type: 'input',
            name: 'amount',
            inputtype: 'number',
            step: 0.01,
            colSpan: '10%',
            setvalue: setValueAmout,
            col: 4,
        },
        {
            type: 'input',
            name: 'priceWithIva',
            inputtype: 'number',
            step: 0.01,
            setvalue: setValuePrice,
            tabeventnew: true,
            colSpan: '20%',
            col: 3,
        },
        {
            type: 'input',
            name: 'percentIva',
            inputtype: 'number',
            colSpan: '10%',
            setvalue: setValueIva,
            step: 0.1,
            col: 3,
        },
        {
            type: 'input',
            name: 'base',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            colSpan: '10%',
            col: 3,
        },
        {
            type: 'input',
            name: 'total',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            colSpan: '20%',
            col: 3,
        },
    ]

    const estructureform = [
        {
            type: 'title',
            name: 'titledata',
            col: 12,
        },
        {
            type: 'autocomplete',
            name: 'state.id',
            service: statebillService.combo,
            col: 2,
        },
        {
            type: 'input',
            name: 'serie',
            conditionalread: checkIsReadOnly,
            col: 2,
        },
        {
            type: 'input',
            name: 'number',
            readonly: true,
            conditionalread: checkIsReadOnly,
            col: 2,
        },
        {
            type: 'input',
            name: 'date',
            inputtype: 'date',
            conditionalread: checkIsReadOnly,
            col: 2,
        },
        {
            type: 'autocomplete',
            name: 'client.id',
            service: userService.combo,
            setvalue: setValueClient,
            conditionalread: checkIsReadOnly,
            searchcode: true,
            autoFocus: true,
            col: 2,
        },
        {
            type: 'input',
            name: 'nif',
            conditionalread: checkIsReadOnly,
            col: 2,
        },
        {
            type: 'input',
            name: 'nameClient',
            col: 2,
        },
        {
            type: 'input',
            name: 'emailClient',
            col: 2,
        },
        ...estructureformStreet,
        {
            type: 'title',
            name: 'titlelineas',
            col: 12,
        },
        {
            type: 'table',
            name: 'lines',
            headers: [
                { label: 'Nombre', colSpan: '30%' },
                { label: 'Cantidad', colSpan: '10%' },
                { label: 'Precio', colSpan: '20%' },
                { label: '% Iva', colSpan: '10%' },
                { label: 'B. Imponible', colSpan: '10%' },
                { label: 'Total', colSpan: '20%' },
            ],
            initrow: { ...initline },
            fields: estructureformline,
            fieldId: 'id',
            setvalue: updateRows,
            conditionalread: checkIsReadOnly,
            col: 12,
        },
        {
            type: 'title',
            name: 'titletotal',
            col: 12,
        },
        {
            type: 'input',
            name: 'discountPercent',
            inputtype: 'number',
            step: 0.01,
            setvalue: updateDiscountTotal,
            conditionalread: checkIsReadOnly,
            col: 2,
        },
        {
            type: 'input',
            name: 'discount',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            col: 2,
        },
        {
            type: 'input',
            name: 'base',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            col: 2,
        },
        {
            type: 'input',
            name: 'iva',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            col: 1,
        },
        {
            type: 'input',
            name: 'total',
            inputtype: 'number',
            step: 0.01,
            readonly: true,
            col: 2,
        },
        {
            type: 'custom',
            name: 'ivasline',
            custom: fieldIvas,
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        clientGeneral = element.client

        objectedit['client'] = element.client
            ? element.client
            : { id: null, name: '' }
        objectedit['number'] = element.number ? element.number : null
        objectedit['state'] = {
            id: element.state && element.state.id ? element.state.id : null,
            name:
                element.state && element.state.name ? element.state.name : null,
        }
        objectedit['nameClient'] = element.nameClient
            ? element.nameClient
            : null
        objectedit['emailClient'] = element.emailClient
            ? element.emailClient
            : null
        objectedit['serie'] = element.serie ? element.serie : null
        objectedit['nif'] = element.nif ? element.nif : null
        objectedit['total'] = element.total ? element.total.toFixed(2) : 0.0
        objectedit['base'] = element.base ? element.base.toFixed(2) : 0.0
        objectedit['discountPercent'] = element.discountPercent
            ? element.discountPercent
            : 0.0
        objectedit['discount'] = element.discount
            ? element.discount.toFixed(2)
            : 0.0
        objectedit['iva'] = element.iva ? element.iva.toFixed(2) : 0.0
        objectedit['tax'] = element.tax ? element.tax.toFixed(2) : 0.0
        objectedit['equivalencia'] = element.equivalencia
            ? element.equivalencia.toFixed(2)
            : 0.0
        getElementStreet(element.street, objectedit['street'])
        objectedit['lines'] = element.lines ? element.lines : []
        objectedit['ivasline'] = element.ivasline ? element.ivasline : []
        objectedit['recargosline'] = element.lines ? element.recargosline : []
        objectedit['note'] = element.note ? element.note : null
        objectedit['date'] = element.date ? element.date : null
        objectedit['isPay'] = element.isPay ? element.isPay : null
        objectedit['payone'] = element.payone ? element.payone : null
        objectedit['payoneDate'] = element.payoneDate
            ? element.payoneDate
            : null
        objectedit['paytwo'] = element.paytwo ? element.paytwo : null
        objectedit['paytwoDate'] = element.paytwoDate
            ? element.paytwoDate
            : null
        objectedit['paythree'] = element.paythree ? element.paythree : null
        objectedit['paythreeDate'] = element.paythreeDate
            ? element.paythreeDate
            : null
        objectedit['payTotal'] = element.isPay
            ? element.total
            : element.payTotal
            ? element.payTotal
            : 0
        objectedit['payLess'] = element.isPay
            ? 0
            : element.payLess
            ? element.payLess
            : 0
        objectedit['hiddenTax'] = element.hiddenTax ? element.hiddenTax : false

        setHiddenTax(objectedit['hiddenTax'])

        return objectedit
    }

    const send = () => {
        service.send(id, (data, erro) => {
            toast.success('Se ha enviado al cliente corrcetamente', {
                position: toast.POSITION.TOP_CENTER,
            })
        })
    }

    const print = (serie, number) => {
        let fileName =
            'Factura' + (serie ? serie : '') + pad(+number, 5) + '.pdf'
        service.print(id, fileName, (data, erro) => {
            toast.success('Se ha descargado la factura', {
                position: toast.POSITION.TOP_CENTER,
            })
        })
    }

    const printDirect = (serie, number) => {
        let fileName =
            'Factura' + (serie ? serie : '') + pad(+number, 5) + '.pdf'
        service.printDirect(id, fileName, (data, erro) => {
            toast.success('Se ha descargado la factura', {
                position: toast.POSITION.TOP_CENTER,
            })
        })
    }

    const rectificative = () => {
        service.rectificative(id, (data, erro) => {
            if (data && data.data && data.data.id) {
                navigate('/bill/edit/' + data.data.id)
                window.location.reload()
            } else {
                toast.error(erro.mes, { position: toast.POSITION.TOP_CENTER })
            }
        })
    }

    const generateAndSave = (getObject) => {
        getObject().state = { id: 2, name: '' }
        setForcesave(true)
    }

    const moreActions = (getObject) => {
        var isGenerate =
            id &&
            getObject &&
            getObject() &&
            getObject().number &&
            getObject().number != '' &&
            getObject().state &&
            getObject().state.id &&
            getObject().state.id != 1
        var haveEmail =
            id &&
            getObject &&
            getObject() &&
            getObject().emailClient &&
            getObject().emailClient != ''
        var isPay = id && getObject && getObject() && getObject().isPay
        return (
            <>
                {isGenerate && haveEmail && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={send}
                    >
                        {' '}
                        Enviar al cliente
                    </Button>
                )}
                {isGenerate && (
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={(evt) =>
                            print(getObject().serie, getObject().number)
                        }
                    >
                        {' '}
                        Descargar Factura{' '}
                    </Button>
                )}
                {isGenerate && (
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={(evt) =>
                            printDirect(getObject().serie, getObject().number)
                        }
                    >
                        {' '}
                        Imprimir{' '}
                    </Button>
                )}
                {isGenerate && (
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={rectificative}
                    >
                        {' '}
                        Generar rectificativa{' '}
                    </Button>
                )}
                {isPay && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={(evt) => setOpenDeletePay(!openDeletePay)}
                    >
                        {' '}
                        Borrr pago{' '}
                    </Button>
                )}
                {!isGenerate && (
                    <span>
                        No se puede enviar al cliente, ni al asesor, ni imprimir
                        hasta que se genere, para generarlo debes cambiar el
                        estado a distinto de Borrador{' '}
                    </span>
                )}
                {!isGenerate && (
                    <Button
                        type="button"
                        variant="contained"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={(evt) => generateAndSave(getObject)}
                    >
                        {' '}
                        Guardar y generar{' '}
                    </Button>
                )}
            </>
        )
    }

    const removeLines = function (object) {
        var other = true
        var posRemove = -1
        for (var i = 0; i < object['lines'].length; i++) {
            var r = object['lines'][i]
            if (!r['amount'] || r['amount'] == 0) {
                posRemove = i
                break
            }
        }

        if (posRemove > -1) {
            object['lines'].splice(posRemove, 1)
            removeLines(object)
        }
    }

    const handleCloseDeletePay = () => {
        setOpenDeletePay(!openDeletePay)
    }

    const deletePay = () => {
        setOpenDeletePay(!openDeletePay)
        service.deletepay(id, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            }
        })
    }

    const isValid = function (object) {
        removeLines(object)
        if (!object['state']) {
            return t('bill.error.state')
        }
        if (!object['nameClient']) {
            return t('bill.error.name')
        }
        if (!object['lines'] || object['lines'].length == 0) {
            return t('bill.error.lines')
        }
        if (!object['nif']) {
            return t('bill.error.nif')
        }

        return null
    }

    const haveOtherTop = function (idRow) {
        return !idRow || idRow <= 0
    }

    const saveClient = (client) => {
        clientGeneral = client

        var aux = []
        aux['client'] = client
        aux['client']['name'] = client.comercialName
        aux['nameClient'] = client.comercialName
        aux['emailClient'] = client.email
        aux['nif'] = client.nif
        aux['street'] = client.street
        aux['paymentType'] = {
            id: client.paymentType.id,
            name: client.paymentType.name,
        }
        aux['route'] = { id: client.route.id, name: client.route.name }
        aux['recargoEqui'] = client.recargoEqui
        aux['exentoIva'] = client.exentoIva
        if (client.dtoBill && client.dtoBill > 0) {
            aux['discountPercent'] = client.dtoBill
        }

        setObjectform((currentValueform) => ({ ...currentValueform, ...aux }))
    }

    useEffect(() => {
        clientGeneral = { id: null, comercialName: null }
    }, [])

    const notierror = () => {
        setForcesave(false)
    }

    return (
        <>
            <CrudForm
                objectform={objectform}
                estructureform={estructureform}
                i18n={'bill'}
                urledit={'/bill/edit/'}
                urlCancel={'/bill'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                moreActions={moreActions}
                haveotherTop={haveOtherTop}
                reload={reload}
                forcesave={forcesave}
                notierror={notierror}
            />

            <ToastContainer />
        </>
    )
}
