import CrudTable from '../../crud/CrudTable'
import { packService as service } from '../../../services'

const initFilter = {
    name: null,
}

export default function Pack() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
    ]

    const getUrlImage = function (namefile) {
        if (namefile) {
            if (namefile.startsWith('http')) {
                return namefile
            } else {
                var nameImage = namefile
                nameImage = nameImage.startsWith('/')
                    ? nameImage
                    : '/' + nameImage
                return process.env.REACT_APP_HOST + '/image' + nameImage
            }
        } else {
            return ''
        }
    }

    const paintCell = {
        images: (value, object) => {
            if (value && value[0] && value[0].path) {
                const url = getUrlImage(value[0].path)
                return (
                    <img
                        src={url}
                        style={{
                            width: '70px',
                            height: 'auto',
                            maxWidth: '100%',
                            minWidth: '70px',
                        }}
                    />
                )
            } else {
                return <></>
            }
        },
        description: (value, object) => {
            if (value) {
                return <>{value.replace(/<\/?[^>]+(>|$)/g, '')}</>
            } else {
                return <></>
            }
        },
    }

    return (
        <CrudTable
            service={service}
            urledit={'/pack/edit/'}
            urlnew={'/pack/new/'}
            filter={initFilter}
            i18n={'pack'}
            paintCell={paintCell}
            fieldstable={['images', 'name', 'description', 'price']}
            estructureForm={createEstructureFilter}
        />
    )
}
