import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import CrudForm from "../../../crud/CrudForm"
import {statebillService as service} from "../../../../services"
import { useTranslation } from 'react-i18next';

const initElement = {
     name: "",
  };


  
export default function FormStatebill() {
  const [objectform, setObjectform] = React.useState({...initElement});
  type FormParms= {
    id: string;
  };
  let { id } = useParams<FormParms>();
  const [idRow, setIdrow] = useState(id?Number(id):0);
  const { t, i18n } = useTranslation();


  const estructureform =  [
          {
           type:"input",
           name: "name",
           col: 4
          }
    ];

  const recoveryElement = function(objectedit,element){

    objectedit['name']=element.name?element.name:"";
    return objectedit;
  }




  const isValid = function(object){
    if(!object['name']){
      return t("cartype.error.name");
    }

    return null;
}

  return (
    <><CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"statebill"}
        urledit={"/statebill/edit/"}
        urlCancel={"/statebill"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
    </>

  );
}
