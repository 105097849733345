import CrudTable from '../../crud/CrudTable'
import { companyService as service } from '../../../services'

const initFilter = {
    name: null,
    code: null,
}

export default function Company() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'code',
            col: 4,
        },
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/company/edit/'}
            urlnew={'/company/new/'}
            filter={initFilter}
            i18n={'company'}
            fieldstable={['picture', 'code', 'cif', 'name', 'phone', 'email']}
            estructureForm={createEstructureFilter}
        />
    )
}
