import CrudTable from '../../../crud/CrudTable'
import { locationRentService as service } from '../../../../services'

const initFilter = {
    name: null,
}

export default function LocationRent() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/locationrent/edit/'}
            urlnew={'/locationrent/new/'}
            filter={initFilter}
            i18n={'locationrent'}
            fieldstable={['name']}
            estructureForm={createEstructureFilter}
        />
    )
}
