import CrudTable from '../../crud/CrudTable'
import { couponService as service } from '../../../services'

const initFilter = {
    name: null,
    code: null,
    emails: null,
    from: null,
    to: null,
}

export default function Coupon() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'code',
            col: 4,
        },
        {
            type: 'input',
            name: 'emails',
            col: 4,
        },
        {
            type: 'input',
            name: 'from',
            inputtype: 'date',
            col: 4,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'date',
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/coupon/edit/'}
            urlnew={'/coupon/new/'}
            filter={initFilter}
            i18n={'coupon'}
            fieldstable={[
                'code',
                'name',
                'start_date',
                'end_date',
                'km_discount',
                'time_discount',
                'time_standby_discount',
                'percentage_discount',
                'emails',
                'multiple_uses',
            ]}
            estructureForm={createEstructureFilter}
        />
    )
}
