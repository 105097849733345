import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps'

export const ControlRouteMap = (props) => {
    const map = useMap()

    const [points, setPoints] = React.useState<any[]>([])

    useEffect(() => {
        if (!map) return

        drawRoute(map)
    }, [map])

    const drawRoute = (map) => {
        debugger
        if (props.route && map) {
            var route = props.route
            var bounds = new google.maps.LatLngBounds()

            var pointsRouteString = route.split('#')

            var points: any[] = []

            pointsRouteString.forEach((element) => {
                var pcoord = element.split(';')
                if (pcoord.length == 2) {
                    points.push({
                        lat: +pcoord[0],
                        lng: +pcoord[1],
                    })
                    bounds.extend(
                        new google.maps.LatLng(+pcoord[0], +pcoord[1])
                    )
                }
            })

            var flightPath = new google.maps.Polyline({
                path: points,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
            })

            flightPath.setMap(map)
            map?.fitBounds(bounds)
        }
    }

    return <></>
}
