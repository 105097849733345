import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { markService as service, marktypeService } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    name: '',
    marks: [],
    type: { id: null, name: '' },
}

export default function FormMark() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'name',
            col: 6,
        },
        {
            type: 'input',
            name: 'lat',
            col: 3,
        },
        {
            type: 'input',
            name: 'lng',
            col: 3,
        },
        {
            type: 'input',
            name: 'address',
            col: 6,
        },
        {
            type: 'input',
            name: 'phone',
            col: 3,
        },
        {
            type: 'input',
            name: 'web',
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'type.id',
            service: marktypeService.combo,
            col: 6,
        },
        {
            type: 'checkbox',
            name: 'visible',
            col: 3,
        },
        {
            type: 'textarea',
            name: 'summary',
            col: 12,
        },
        {
            type: 'title',
            name: 'titlegal',
            col: 12,
        },
        {
            type: 'gallery',
            name: 'images',
            imagekey: 'path',
            altkey: 'alt',
            idkey: 'id',
            col: 12,
        },
        {
            type: 'editor',
            name: 'description',
            height: 600,
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('trip.error.name')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'mark'}
                urledit={'/mark/edit/'}
                urlCancel={'/mark'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
