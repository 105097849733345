import React, { useState, useEffect } from 'react'
import { Container } from '@mui/material'

export default function ForceUpdate(props) {
    return (
        <div
            style={{
                background: 'white',
                minHeight: '100vh',
                minWidth: '100%',
            }}
        >
            <img
                src={'/splashtop.png'}
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    width: '400px',
                    maxWidth: '50%',
                }}
            />
            <Container
                style={{
                    textAlign: 'center',
                    margin: 'auto',
                    display: 'flex',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <img
                    src={'/logo.png'}
                    style={{
                        width: '400px',
                        maxWidth: '80%',
                        marginBottom: '2em',
                    }}
                />
                <p>
                    Hemos mejorado nuestra plataforma, por lo que es necesario
                    que actualices la aplicación.
                </p>

                <a
                    href="https://onelink.to/fgde25"
                    target="_blank"
                    style={{
                        color: 'white',
                        textDecoration: 'none',
                        padding: '1em 05em',
                        background: '#83c63b',
                        borderRadius: '6px',
                    }}
                >
                    Actualizar
                </a>
            </Container>
            <img
                src={'/splashbottom.png'}
                style={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    width: '400px',
                    maxWidth: '50%',
                }}
            />
        </div>
    )
}
