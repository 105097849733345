import CrudTable from '../../../crud/CrudTable'
import {
    pricesService as service,
    cartypeService,
    companyService,
} from '../../../../services'

const initFilter = {
    name: null,
}

export default function Prices() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'cartype',
            col: 4,
            service: cartypeService.combo,
        },
        {
            type: 'autocomplete',
            name: 'company',
            col: 4,
            service: companyService.combo,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/prices/edit/'}
            urlnew={'/prices/new/'}
            i18n={'prices'}
            filter={initFilter}
            fieldstable={[
                'name',
                'carType.name',
                'company.name',
                'rent_1',
                'rent_2',
                'rent_3',
                'rent_5',
                'rent_7',
                'rent_14',
                'rent_30',
                'price_km',
                'price_standby',
            ]}
            estructureForm={createEstructureFilter}
        />
    )
}
