import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import CrudTab from '../../crud/CrudTab'
import { pricesService, cartypeService } from '../../../services'
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify'
import Grid from '@mui/material/Grid'

export default function TabPrices(props) {
    const [filter, setFilter] = useState({
        car: null,
        company: props.idcompany,
        cartype: null,
    })

    const { t } = useTranslation()

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'cartype',
            col: 4,
            service: cartypeService.combo,
        },
    ]

    useEffect(() => {}, [])

    useEffect(() => {
        setFilter({
            car: null,
            company: props.idcompany,
            cartype: null,
        })
    }, [props.idcompany])

    return (
        <>
            <CrudTab
                service={pricesService}
                urledit={'/prices/edit/'}
                filter={filter}
                i18n={'prices'}
                fieldstable={[
                    'name',
                    'carType.name',
                    'company.name',
                    'rent_1',
                    'rent_2',
                    'rent_3',
                    'rent_5',
                    'rent_7',
                    'rent_14',
                    'rent_30',
                    'price_km',
                    'price_min',
                    'price_standby',
                ]}
                estructureForm={createEstructureFilter}
                haveother={false}
                deleteaction={false}
                selectable={false}
            />
            <ToastContainer />
        </>
    )
}
