import CrudTable from '../../crud/CrudTable'
import { adsService as service } from '../../../services'

const initFilter = {
    active: -1,
    from: null,
    to: null,
    adsAction: null,
    adsType: null,
}

export default function Ads() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'from',
            inputtype: 'datetime-local',
            col: 3,
        },
        {
            type: 'input',
            name: 'to',
            inputtype: 'datetime-local',
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'adsType',
            options: [
                { key: 'PRIN', name: 'Principales' },
                { key: 'SECOND', name: 'Secundario' },
            ],
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'adsAction',
            options: [
                { key: 'LINK', name: 'Link' },
                { key: 'MONEY', name: 'Monedero' },
                { key: 'SHARE', name: 'Share' },
                { key: 'RENT', name: 'Rental' },
            ],
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'active',
            options: [
                { key: -1, name: 'Todos' },
                { key: 0, name: 'Activos' },
                { key: 1, name: 'Desactivados' },
            ],
            col: 4,
        },
    ]

    const getUrlImage = function (namefile) {
        if (namefile) {
            if (namefile.startsWith('http')) {
                return namefile
            } else {
                var nameImage = namefile
                nameImage = nameImage.startsWith('/')
                    ? nameImage
                    : '/' + nameImage
                return process.env.REACT_APP_HOST + '/image' + nameImage
            }
        } else {
            return ''
        }
    }

    const paintCell = {
        images: (value, object) => {
            if (value && value[0] && value[0].path) {
                const url = getUrlImage(value[0].path)
                return (
                    <img
                        src={url}
                        style={{
                            width: '70px',
                            height: 'auto',
                            maxWidth: '100%',
                            minWidth: '70px',
                        }}
                    />
                )
            } else {
                return <></>
            }
        },
    }

    return (
        <CrudTable
            service={service}
            urledit={'/ads/edit/'}
            urlnew={'/ads/new/'}
            filter={initFilter}
            i18n={'ads'}
            paintCell={paintCell}
            fieldstable={[
                'picture',
                'name',
                'startDate',
                'endDate',
                'adsAction',
                'adsType',
            ]}
            estructureForm={createEstructureFilter}
        />
    )
}
