import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import {
    packService as service,
    markService,
    tripService,
} from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    name: '',
    points: [],
    trip: { id: -1, name: '' },
    url: '',
}

const initElementLine = {
    name: '',
    mark: { id: -1, name: '' },
}

export default function FormPack() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureformLine = [
        {
            type: 'autocomplete',
            name: 'mark',
            colSpan: '50%',
            service: markService.combo,
            col: 6,
        },
        {
            type: 'input',
            name: 'position',
            inputtype: 'number',
            step: 0.01,
            colSpan: '25%',
            col: 4,
        },
        {
            type: 'input',
            name: 'hour',
            colSpan: '25%',
            col: 4,
        },
    ]

    const estructureform = [
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'price',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'input',
            name: 'url',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'trip.id',
            service: tripService.combo,
            col: 8,
        },
        {
            type: 'title',
            name: 'titlemarks',
            col: 12,
        },
        {
            type: 'table',
            name: 'points',
            headers: [
                { label: 'Punto', colSpan: '40%' },
                { label: 'Posicion', colSpan: '30%' },
                { label: 'Hora', colSpan: '30%' },
            ],
            initrow: { ...initElementLine },
            fields: estructureformLine,
            fieldId: 'id',
            labelbtn: 'Añadir punto',
            col: 12,
        },
        {
            type: 'title',
            name: 'titlegal',
            col: 12,
        },
        {
            type: 'gallery',
            name: 'images',
            imagekey: 'path',
            altkey: 'alt',
            idkey: 'id',
            col: 12,
        },
        {
            type: 'editor',
            name: 'description',
            height: 600,
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('pack.error.name')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'pack'}
                urledit={'/pack/edit/'}
                urlCancel={'/pack'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
