import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { alertService as service, userService } from '../../../services'
import { useTranslation } from 'react-i18next'

const initElement = {
    title: '',
    desc: '',
    user: { id: -1, name: '' },
}

export default function FormAlert() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'user.id',
            service: userService.combo,
            col: 6,
        },
        {
            type: 'input',
            name: 'title',
            col: 6,
        },
        {
            type: 'textarea',
            name: 'desc',
            col: 6,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['title']) {
            return t('alerts.error.title')
        }
        if (!object['desc']) {
            return t('alerts.error.desc')
        }

        if (
            !object['user'] ||
            !object['user']['id'] ||
            object['user']['id'] < 1
        ) {
            return t('alerts.error.user')
        }

        return null
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'alerts'}
                urledit={'/alerts/edit/'}
                urlCancel={'/alerts'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
            />
        </>
    )
}
